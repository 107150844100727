function togglePasswordVisibility() {
  let input = document.getElementById("login-password");
  let icon = document.querySelector(".pwd-visibility-toggle i");

  if (input.type === "password") {
    input.type = "text";
    icon.className = 'icon-hidden';
  } else {
    input.type = "password";
    icon.className = 'icon-opened';
  }
}